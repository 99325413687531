<!-- 编辑轮播图 -->
<template>
  <div>
    <el-dialog :title="dialog.title" :visible.sync="dialog.dialogVisible" @close="handleClose" width="520px">
      <el-form :model="params" :rules="rules" ref="ruleForm" label-position="left" label-width="80px">
        <el-form-item prop="imageUrl" label-width="0">
          <uploadPlace :isUpload="dialog.handleType === 3" v-model="params.imageUrl" :styleObject="{ width: '460px', height: '160px', maring: '0 auto' }" :borderRadius="'0px'" />
          <div>建议尺寸：600*200</div>
        </el-form-item>
        <el-form-item class="input_wrapper" label="排序序号" prop="sortCode">
          <el-input v-model="params.sortCode" placeholder="请输入数字" v-Int />
        </el-form-item>
        <el-form-item class="input_wrapper" label="链接地址" prop="redirectUrl">
          <el-input v-model="params.redirectUrl" type="textarea" :rows="3" resize="none" placeholder="请输入链接地址" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="dialog.handleType !== 3">
        <el-button @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadPlace from '@/components/upload/uploadPlace.vue'
export default {
  name: '',
  props: {
    dialog: {
      type: Object,
      default: function(value) {
        return {
          title: '提示',
          dialogVisible: false
        }
      }
    },
    bannerInfo: {
      type: Object,
      default: () => {
        return {
          bannerId: '',
          imageUrl: '',
          redirectUrl: '',
          sortCode: ''
        }
      }
    }
  },
  data() {
    return {
      params: {},
      rules: {
        imageUrl: [{ message: '请上传图片', required: true, trigger: 'blur' }],
        sortCode: [{ message: '请输入数字', required: true, trigger: 'blur' }],
        redirectUrl: [{ message: '请输入链接地址', required: true, trigger: 'blur' }]
      }
    }
  },
  computed: {},
  watch: {
    bannerInfo(v) {
      this.params = this.$cloneDeep(this.bannerInfo)
      console.log(this.params)
    }
  },
  methods: {
    handleConfirm() {
      this.$refs['ruleForm'].validate(valid => {
        if (!valid) return

        this.$emit('confirm', this.params)
      })
    },
    handleClose() {
      this.$refs['ruleForm'].resetFields()
    }
  },
  mounted() {},
  components: {
    uploadPlace
  }
}
</script>
<style lang="less" scoped>
.input_wrapper {
  .input_title {
    color: #000;
  }
}
</style>
