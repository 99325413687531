import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 获取轮播图列表
 * @returns
 */
export const bannerList = ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/banner/list`,
      method: 'get',
      params
    })
  )
}

/**
 * 添加、修改轮播图
 * @param {*} "bannerId" 轮播图id
 * @param {*} "imageUrl" 图片链接
 * @param {*} "status" 状态 0-无效 1-有效
 * @param {*} "redirectUrl" 轮播图跳转链接
 * @returns
 */
export const bannerUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/banner/update`,
      method: 'POST',
      data
    })
  )
}
/**
 * 删除轮播图
 * @param {*} "bannerId" 轮播图id
 * @returns
 */
export const bannerDelete = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/banner/delete`,
      method: 'POST',
      data
    })
  )
}
