<!-- 轮播图 -->
<template>
  <div class="container">
    <TitleBarBase />
    <div class="handle_bar">
      <el-button type="primary" @click="handleEdit(1)">添加</el-button>
    </div>
    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="sortCode" label="排序" align="center" width="50"></el-table-column>
      <!-- <el-table-column prop="nickName" label="标题" align="center"></el-table-column> -->
      <el-table-column label="商品图片" align="center" width="300">
        <template slot-scope="scope">
          <img :src="scope.row.imageUrl" alt style="width:202px;height:50px;" />
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="链接" align="center">
        <template slot-scope="scope">
          <a class="link" target="_blank" :href="scope.row.redirectUrl">{{ scope.row.redirectUrl }}</a>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="handleEdit(2, scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
    <EditBannerDialog :dialog="editBannerDialog" :bannerInfo="bannerInfo" @confirm="editBannerConfirm" />
  </div>
</template>

<script>
import TitleBarBase from '@/components/TitleBar/TitleBarBase.vue'
import EditBannerDialog from './components/EditBannerDialog.vue'
import { bannerList, bannerUpdate, bannerDelete } from '@/api/BannerlistController.js'
export default {
  components: {
    TitleBarBase,
    EditBannerDialog
  },
  data() {
    return {
      tableList: [],
      params: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      editBannerDialog: {
        title: '添加轮播图',
        dialogVisible: false,
        handleType: 1
      },
      bannerInfo: {
        bannerId: '',
        imageUrl: '',
        redirectUrl: '',
        sortCode: ''
      }
    }
  },
  mounted() {
    this.getBannerList()
  },
  methods: {
    async editBannerConfirm(params) {
      console.log(params)
      let [err, res] = await bannerUpdate(params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      this.editBannerDialog.dialogVisible = false
      this.getBannerList()
    },
    async getBannerList() {
      let [err, res] = await bannerList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      console.log(res)
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // type: 1-添加 2-编辑
    handleEdit(type, row) {
      this.editBannerDialog.handleType = type
      this.editBannerDialog.dialogVisible = true
      if (type === 1) {
        return (this.bannerInfo = {
          imageUrl: '',
          redirectUrl: '',
          sortCode: ''
        })
      }
      this.bannerInfo = {
        bannerId: row.bannerId,
        imageUrl: row.imageUrl,
        redirectUrl: row.redirectUrl,
        sortCode: row.sortCode
      }
    },
    async handleDelete(row) {
      await this.$repeatedConfirm()
      let [err, res] = await bannerDelete({ bannerId: row.bannerId })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      this.getBannerList()
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.getBannerList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.getBannerList()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .handle_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 15px 0;
    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .link {
    color: #1296db;
  }
  .pageCo {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
  }
}
</style>
